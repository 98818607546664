#search{
    #header-section{
        #app-logo{
            max-height: 250px;
            max-width: 100%;
            &:hover{
                cursor: pointer;
            }
        }
    }



}