.user-card{
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: 100px;

    border-radius: 45px 0 0 45px;

    &:hover{
        box-shadow: rgba(41, 41, 41, 0.400) 0px 4px 12px;
        cursor: pointer;
    }

    .picture{
        img{
            height: 100%;
            width: 100px;
            border-radius: 45px;
        }
    }
    .details{
        height: 100%;
        width: calc(100% - 100px);
        padding-left: 10px;
        padding-right: 5px;
        .fullname{
            color:#DE7D26;
            font-weight: bold;
        }

        .company{
            font-weight: bold;
        }
    }
}