
#user-details-panel{
    .picture {
        text-align: center;
        img{
            width: 100%;
        }
    }

    .sector{
        background-color: black;
        color: #fff;
        padding: 5px;
        border-radius: 2px;
    }

    .fullname{
        font-weight: bold;
    }

    .description{
        border-top: 1px solid #979797;
        margin-top: 10px;
        padding-top: 10px;
    }

    .arrow{
        
        text-align: center;
        &:hover{
            background-color: #f5f5f5;
            cursor: pointer;
        }

        div{
            height: 100%;
            position: relative;
            top: 45%;
        }
    }

    .show-more{
        a{
            color: #DE7D26;
            &:link{color: #DE7D26;}
            &:visited {color: #DE7D26;}
            &:hover {color: #DE7D26;}
            &:active {color: #DE7D26;}
        }
    }
}