.btn-orange{
    background-color: #DE7D26!important;
    border-color: #DE7D26!important;
    &:hover{
        background-color: #de7c26c9!important;
        border-color: #DE7D26!important;
    }
}

.color-orange{
    color: #DE7D26!important;;
}