#homepage{
    #header-section{
        #app-logo{
            max-height: 500px;
            &:hover{
                cursor: pointer;
            }
        }
    }

    #introduction{
        max-width: 600px;
        padding: 20px;
        
        text-align: justify;
        
    
        div{
            margin-bottom: 10px;
        }
    }
}
