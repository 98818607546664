#footer{
    //position: fixed;
    bottom: 0;

    width: 100%;
    height: 68px;
    background-color: #595959;
    color: #fff;
    padding: 10px;
    font-size: 14px;


    a{
        &:link{color: #fff;}
        &:visited {color: #fff;}
        &:hover {color: #fff;}
        &:active {color: #fff;}
    }

    .links{
        @media screen and (max-width: 500px) {
            font-size: 10px;
            div{
                margin-top: 5px;
            }
        }
    }

    #logo-linkedin{
        height: 30px;


    }
    #logo-fno{
        height: 50px;
        @media screen and (max-width: 500px) {
            height: 40px;
            margin-top: 5px;
        }
        @media screen and (max-width: 400px) {
            height: 30px;
            
        }

        @media screen and (max-width: 300px) {
            height: 20px;
            margin-top: 10px;
        }
    }
}